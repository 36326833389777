import { gsap } from "gsap";
const motionMq = window.matchMedia('(prefers-reduced-motion)');

const animatePage = function() {

    const timeline = gsap.timeline();
    const delay = 0.1;
    const ease = 'power4.inOut';

    const sectionLeft = document.querySelectorAll('section.col-left');
    const sectionRight = document.querySelectorAll('section.col-right');
    const images = document.querySelectorAll('img');
    const content = document.querySelector('div.content');
    const duration = 2;
    timeline
        .set(sectionLeft, {y: '150%'})
        .set(sectionRight, {y: '-150%'})
        .set(images, {opacity: 0, x: '-50'})
        .set(content, {opacity: 0, x: '-50'})
        .to(sectionLeft, {
            y: 0,
            duration: duration,
            delay: delay,
            ease: ease,
        }, 0)
        .to(sectionRight, {
            y: 0,
            duration: duration,
            delay: delay,
            ease: ease,
        }, 0)
        .to(images, {
            x: 0,
            duration: 2.5,
            opacity: 1,
            ease: ease,
            delay: delay,
        }, 1)
        .to(content, {
            x: 0,
            duration: 2.5,
            opacity: 1,
            ease: ease,
            delay: delay,
        }, 1)

}

if( !motionMq.matches ) {
    animatePage();
}